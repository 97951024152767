import React from 'react';
import { useLocation } from 'react-router-dom';
import WrapperDiv from '../WrapperDiv';
import PageHeader from './PageHeader';
import { ContentTypeTable, TableFooter } from '../ContentTypeTable';
import Pagination from '../Pagination';

import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';

const FETCH_PLANS = gql`
    query($page: Int, $perPage: Int) {
        listPlans(page: $page, per_page: $perPage) {
            results {
                id
                title
                rendered_title
                publishing_status
                created_at
            }
            pagination {
                current
                total
            }
        }
    }
`;

const Plans = props => {
    const location = useLocation();
    let params = new URLSearchParams(location.search);

    const { loading, error, data } = useQuery(FETCH_PLANS, {
        variables: {
            page: parseInt(params.get('page')) || 1,
            perPage: parseInt(params.get('pageSize')) || 25
        }
    });

    const headers = [
        { key: 'id', label: 'Id' },
        { key: 'title', label: 'Title' },
        { key: 'rendered_title', label: 'Label' },
        { key: 'publishing_status', label: 'Status' },
        { key: 'created_at', label: 'Created' }
    ];

    const _onChangePage = (page, pageSize) => {
        // add or update page number in url query
        let currentURLParams = new URLSearchParams(window.location.search);
        currentURLParams.set('page', page);
        currentURLParams.set('pageSize', pageSize);
        props.history.push({ pathname: window.location.path, search: currentURLParams.toString()})
    };

    if( loading ) return <>Loading...</>;
    if( error ) return <>Error {error}</>;

    const plans = data.listPlans.results;
    const pagination = data.listPlans.pagination;

    return(
        <WrapperDiv>
            <PageHeader title="Plans" button={{href:"/plans/editor", icon:"add", text:"Add plan"}}/>
            <React.Fragment>
                <ContentTypeTable headers={ headers } rows={ plans } columnWidths={[80, 200, 240, 200, null]} typename='plan' />
                <TableFooter>
                    <Pagination onChange={ _onChangePage } currentPage={pagination.current} pageSize={25} totalItems={pagination.total}/>
                </TableFooter>
            </React.Fragment>
        </WrapperDiv>
    );
};

export default Plans;