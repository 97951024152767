import React from 'react';
import styled from 'styled-components';
import { ControlGroup, InputGroup, Button, HTMLSelect, FormGroup } from '@blueprintjs/core';

const Header = styled.header`
    display: flex;
    justify-content: space-between;
    height: 24px;
    margin-bottom: 16px;
`;

class TableHeader extends React.Component {
    state = {}

    _onChange = (e) => {
        let { name, value } = e.target;
        let object = {};
        object[name] = value;
        this.setState(object);
    }

    render() {
        return(
            <Header>
                <ControlGroup>
                    { this.props.searchType &&
                        <HTMLSelect name="sort" value={this.props.searchType} onChange={this.props.onChangeSearchType}>
                            <option value="default">default</option>
                            <option value="tags">tags</option>
                        </HTMLSelect>
                    }
                    <InputGroup name="keyword" placeholder="Search content..." onChange={this._onChange}/>
                    <Button icon="arrow-right" onClick={this.props.onSearch.bind(this, this.state.keyword)}/>
                </ControlGroup>
                {this.props.hasSorting &&
                    <FormGroup label="Sort by" inline={true}>
                        <ControlGroup>
                            <HTMLSelect name="sort" value={this.props.sortedBy} onChange={this.props.onSort}>
                                {this.props.sortableColumns.map(item => <option key={item} value={item}>{item}</option>)}
                            </HTMLSelect>
                            <Button icon={ this.props.sortingOrder === 'asc' ? 'sort-asc' : 'sort-desc'} onClick={this.props.onSortOrder}/>
                        </ControlGroup>
                    </FormGroup>
                }
            </Header>
        );
    }
}

export default TableHeader;
