import React from 'react';
import styled from 'styled-components';
import { ProgressBar } from '@blueprintjs/core';

const Card = styled.div`
    margin-bottom: 32px;

    header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
`;

const RDIRef = {
    'calories': 2000,
    'fat': 70,
    'saturates': 20,
    'carbohydrates': 260,
    'sugars': 90,
    'protein': 50,
    'fibre': 30,
    'salt': 6
};

// to avoid miscalculations with the nutritional info
// intake for each nutrient is save as an INT multiplying
// the original value for 10000
// - see the backend in the save method for Ingredient
const evalNutrition = (value) => {
    if(value === 0 || isNaN(value)) return value;
    return (value/10000).toFixed(2);
}

const evalRDI = (value, slug) => {
    if(value === 0) return value;
    return evalNutrition(value) >= RDIRef[slug] ? 
        1 : evalNutrition(value)/RDIRef[slug];
}

/*
const renderIntent = (value, slug) => {
    var progressValue = evalRDI(value, slug);
    if(['fat','saturates','salt'].includes(slug)) {
        if(progressValue >= 0.5 && progressValue < 0.7) return 'warning';
        if(progressValue >= 0.7) return 'danger';
    }
    if(['carbohydrates'].includes(slug)) {
        if(progressValue >= 0.3 && progressValue < 1) return 'warning';
    }
    return '';
}
*/

export default (props) => (
    <Card>
        <header>
            <p>{props.label}</p>
            <p>{evalNutrition(props.value)}</p>
        </header>
        {props.slug &&
            <footer>
                <ProgressBar 
                    animate={false} 
                    //intent={renderIntent(props.value, props.slug)} 
                    value={evalRDI(props.value, props.slug)}
                />
            </footer>
        }
    </Card>
);