import React from 'react';
import { useLocation } from 'react-router-dom';
import WrapperDiv from '../WrapperDiv';
import PageHeader from './PageHeader';
import { ContentTypeTable, TableHeader, TableFooter } from '../ContentTypeTable';
import Pagination from '../Pagination';

import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';

const FETCH_INGREDIENTS = gql`
    query($page: Int, $perPage: Int, $searchKey: String, $searchFields: String) {
        listIngredients(page: $page, per_page: $perPage, search_key: $searchKey, search_fields: $searchFields) {
            results {
                id
                name
                category
                qty
                unit
                created_at
            }
            pagination {
                current
                total
            }
        }
    }
`;

const Ingredients = props => {
    const location = useLocation();
    let params = new URLSearchParams(location.search);

    const { loading, error, data, refetch } = useQuery(FETCH_INGREDIENTS, {
        variables: {
            page: parseInt(params.get('page')) || 1,
            perPage: parseInt(params.get('pageSize')) || 25
        }
    });

    const headers = [
        { key: 'id', label: 'Id' },
        { key: 'name', label: 'Name' },
        { key: 'category', label: 'Aisle' },
        { key: 'qty', label: 'Quantity' },
        { key: 'unit', label: 'Unit' },
        { key: 'created_at', label: 'Created' }
    ];

    const _onSearch = (keyword) => {
        return refetch({
            searchKey: keyword,
            searchFields: 'name'
        });
    };

    const _onChangePage = (page, pageSize) => {
        // add or update page number in url query
        let currentURLParams = new URLSearchParams(window.location.search);
        currentURLParams.set('page', page);
        currentURLParams.set('pageSize', pageSize);
        props.history.push({ pathname: window.location.path, search: currentURLParams.toString()})
    };

    if( loading ) return <>Loading...</>;
    if( error ) return <>Error {error}</>;

    const ingredients = data.listIngredients.results;
    const pagination = data.listIngredients.pagination;

    return(
        <WrapperDiv>
            <PageHeader title="Ingredients" button={{href:"/ingredients/editor", icon:"add", text:"Add ingredient"}}/>
            <React.Fragment>
                <TableHeader onSearch={ _onSearch }/>
                <ContentTypeTable headers={ headers } rows={ ingredients } columnWidths={[80, 200, 100, 100, 100, null]} typename='ingredient' />
                <TableFooter>
                    <Pagination onChange={ _onChangePage } currentPage={pagination.current} pageSize={25} totalItems={pagination.total}/>
                </TableFooter>
            </React.Fragment>
        </WrapperDiv>
    );
};

export default Ingredients;