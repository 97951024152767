import React, { Component } from 'react';
import { MenuItem } from '@blueprintjs/core';
import { Suggest } from '@blueprintjs/select';
import { ApolloConsumer } from 'react-apollo';
import gql from 'graphql-tag';

const FETCH_RECIPES = gql`
    query($page: Int, $pageSize: Int, $searchKey: String, $searchFields: String) {
        listRecipes(page: $page, per_page: $pageSize, search_key: $searchKey, search_fields: $searchFields) {
            results {
                id
                title
            }
            pagination {
                current
                total
            }
        }
    }
`;

class RecipeSuggest extends Component {
    state = {
        suggestions: [],
    }

    _renderSuggestions = (item, { handleClick, modifiers, query }) => {
        if (!modifiers.matchesPredicate) {
            return null;
        }
        //const text = `${film.rank}. ${film.title}`;
        return (
            <MenuItem
                active={modifiers.active}
                disabled={modifiers.disabled}
                label={item.title.toString()}
                key={item.id}
                onClick={handleClick}
                //text={highlightText(text, query)}
            />
        );
    }

    _updateSuggestions = suggestions => {
        this.setState({suggestions});
    }

    _renderInputValue = item => {
        return item.title;
    }

    _onItemSelect = item => {
        this.props.onChange(item);
    }

    render() {
        const { suggestions } = this.state;

        return(
            <ApolloConsumer>
                {client => (
                    <Suggest
                        inputProps={{ 'fill': true }}
                        itemRenderer={this._renderSuggestions}
                        items={suggestions}
                        popoverProps={{ 'minimal': true }}
                        onQueryChange={async (query) => {
                            const { data } = await client.query({
                                'query': FETCH_RECIPES,
                                'variables': { page: 1, pageSize: 50, searchFields: 'title', searchKey: query }
                            });
                            this._updateSuggestions(data.listRecipes.results);
                        }}
                        inputValueRenderer={this._renderInputValue}
                        onItemSelect={this._onItemSelect}
                    />
                )}
            </ApolloConsumer>
        );
    }
}

export default RecipeSuggest;