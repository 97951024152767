import React from 'react';
import { Link } from 'react-router-dom';
import { Classes, Icon } from '@blueprintjs/core';

const LinkWrapper = props => {
    const defaultClasses = [
        Classes.BUTTON, 
        props.minimal ? Classes.MINIMAL : ''
    ];
    return (
        <Link to={props.to} className={defaultClasses.join(' ')}>
            {props.icon &&
            <Icon icon={props.icon} style={{marginLeft: "1px", marginRight: "8px"}}/>
            }
            {props.text}
        </Link>
    );
};

export default LinkWrapper;