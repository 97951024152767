import React from 'react';
import styled from 'styled-components';
import { Classes, Colors } from '@blueprintjs/core';

const FormWrapper = props => (
    <form style={{
        display: 'flex',
        backgroundColor: '#fff',
        marginBottom: 40
    }} onSubmit={props.onSubmit}>{props.children}</form>
);

const BP_labelInfo =`
    .${Classes.TEXT_MUTED} {
        color: ${Colors.RED3};
        font-weight: bold;
    }
`;

const FormBody = styled.div`
    flex: 2
    padding: 32px;
    ${BP_labelInfo}
`;

const FormSidebar = styled.div`
    flex: 1;
    background-color: #e8e8e8;
    min-width: 360px;
    padding: 32px;
    ${BP_labelInfo}
`;

const FormActions = styled.div`
    display: flex;
    margin-top: 24px;
`;

export {
    FormWrapper,
    FormBody,
    FormSidebar,
    FormActions
}