import Auth from "../Auth"

function checkStatus(response) {
    if (response.status >= 200 && response.status < 300) {
        return response
    } else {
        var error = new Error(response.statusText)
        error.response = response
        throw error
    }
}
  
function parseJSON(response) {
    return response.json()
}

function FetchClient({method, data, url}) {
    const AUTH = Auth.isAuthenticated();

    return fetch(url || `${process.env.REACT_APP_API_HOST}/graphql`, {
        method: method || 'GET',
        headers: {
            'Content-Type': 'application/json',
            'access-token': AUTH || ""
        },
        body: JSON.stringify(data)
    })
    .then(checkStatus)
    .then(parseJSON)
}

export default FetchClient