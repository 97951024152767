import React from 'react';
import { NumericInput } from '@blueprintjs/core';

// Wrapper for the Blueprint NumericInput component
// in order to have it return the input element on change
// consistent with other forms components
class NumericInputWrapper extends React.Component {
    constructor(props) {
        super(props);
        this.inputRef = React.createRef();
        this.state = {value: props.value};
    }

    _onValueChange(value, string) {
        this.setState({value});
        this.props.onChange({
            'target': {
                'name': this.inputRef.current.inputElement.name,
                'value': value
            }
        });
    }

    render() {
        let value = this.state.value || this.props.value;
        return(
            <NumericInput fill {...this.props} ref={this.inputRef} onValueChange={this._onValueChange.bind(this)} value={value} />
        );
    }
}

export default NumericInputWrapper;