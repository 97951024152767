import { useEffect, useState } from "react"
import AxiosClient from "../components/AxiosClient"

const Q_MEDIA = `
    query($id: Int) {
        RecipeFindMedia(id: $id) {
            size
            source_url
        }
    }
`;

export default (recipeId) => {
    const [media, setMedia] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);

    useEffect(() => {
        setIsLoading(true);
        if(recipeId) {
            AxiosClient.request({
                url: process.env.REACT_APP_GRAPHQL_URL,
                method: 'post',
                data: {
                    query: Q_MEDIA,
                    variables: {'id': recipeId}
                }
            })
            .then(res => {
                const media = res.data.data.RecipeFindMedia;
                setMedia(media.length > 0 ? media : null);
                setIsLoading(false);
            })
            .catch(err => {
                setIsError(err);
            });
        }
    }, [recipeId]);

    return { media, isLoading, isError, setMedia };
}

