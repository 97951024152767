import React from 'react';
import { withRouter } from 'react-router';
import styled from 'styled-components';
import { Navbar, Alignment, Classes } from '@blueprintjs/core';
import LinkWrapper from '../LinkWrapper';

const NavbarWrapper = styled(Navbar)`
    background-color: #23282d !important;
    box-shadow: none !important;
`;

const Header = props => {
    const hide = ['/'].indexOf(props.location.pathname) > -1;

    if(!hide) 
    {
        return(
            <NavbarWrapper className={[Classes.DARK].join(' ')}>
                <Navbar.Group align={Alignment.LEFT}>
                    <Navbar.Heading>Vegly Admin</Navbar.Heading>
                    <Navbar.Divider />
                    <LinkWrapper minimal to="/home" text="Home"/>
                    <LinkWrapper minimal to="/ingredients" text="Ingredients"/>
                    <LinkWrapper minimal to="/recipes" text="Recipes"/>
                    <LinkWrapper minimal to="/plans" text="Plans"/>
                </Navbar.Group>
                <Navbar.Group align={Alignment.RIGHT}>
                    <LinkWrapper minimal to="/signout" text="Sign out"/>
                </Navbar.Group>
            </NavbarWrapper>
        );
    } 
    else {
        return <div></div>;
    }
};

export default withRouter(Header);