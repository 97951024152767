import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { AnchorButton, Button, Icon, FileInput, Spinner, Colors, Classes } from '@blueprintjs/core';
import AxiosClient from '../../AxiosClient';
import { useRecipeMedia } from "../../../hooks";

const CoverImage = styled.img`
    display: block;
    border-radius: 4px;
    width: 100%;
    height: auto;
    margin-bottom: 16px;
`;

const UploaderWrapper = styled.div`
    display: block;
`;

const PlaceholderBlock = styled.div`
    display: block;
    color: ${Colors.DARK_GRAY5};
    text-align: center;
    border: 4px dashed ${Colors.GRAY3};
    border-radius: 4px;
    height: 180px;
    padding: 32px 0;
`;

const EmptyPlaceholder = styled(PlaceholderBlock)`
    p {
        margin-top: 8px;
    }
`;

const PendingPlaceholder = styled(PlaceholderBlock)``;

const FileInputWrapper = styled(FileInput)`
    display: block;
    width: 100%;

    input[type=file] {
        display: none;
    }

    .${Classes.FILE_UPLOAD_INPUT} {
        background: none;
        box-shadow: none;
        border: 0;
        padding: 0 16px;
        padding-bottom: 80px;

        &:after {
            content: 'Choose image';
            top: 40px;
            left: 0;
            right: 0;
            width: 50%;
            padding: 8px;
            margin: 0 auto;
        }
    }
`;

function CoverUploader({ recipeId, onChange, onRemoveFeaturedImage}) {
    const [pendingUpload, setPendingUpload] = useState(null);
    const [inProgress, setInProgress] = useState(false);
    const [uploadedFile, setUploadedFile] = useState(null);
    const thumbnail = useRecipeMedia(recipeId);

    const _onFileSelected = (e) => {
        let file = e.target.files[0];
        setPendingUpload(file);
    }

    const _onUpload = (e) => {
        e.preventDefault();
        var uploaderFormData = new FormData();
        uploaderFormData.append('file', pendingUpload);
        setInProgress(true);

        AxiosClient.request({
            url: `${process.env.REACT_APP_REST_HOST}/uploader`,
            method: 'post',
            data: uploaderFormData,
            config: {headers: {'Content-Type': 'multipart/form-data'}}
        })
        .then(res => {
            setInProgress(false);
            setUploadedFile(res.data);
            
            onChange({
                'target': {
                    'name': 'featured_image',
                    'value': res.data
                }
            });
        })
        .catch(err => {
            setInProgress(false);
        });
    }

    const _onRemove = () => {
        thumbnail.setMedia(null);
        onRemoveFeaturedImage();
    }

    return(
        <React.Fragment>
            {thumbnail.media &&
                <React.Fragment>
                    <CoverImage alt={`${thumbnail}`} src={`${process.env.REACT_APP_API_HOST}/${thumbnail.media[2].source_url}`}/>
                    <AnchorButton icon="trash" text="Remove image" onClick={_onRemove}/>
                </React.Fragment>
            }
            {!thumbnail.media &&
                <UploaderWrapper>
                    {inProgress && 
                        <EmptyPlaceholder>
                            <Spinner size={80}/>
                        </EmptyPlaceholder>
                    }

                    {(!pendingUpload && !inProgress) && 
                        <EmptyPlaceholder>
                            <Icon icon="upload"/>
                            <FileInputWrapper fill={false} text="Add a featured image" onInputChange={_onFileSelected}/>
                        </EmptyPlaceholder>
                    }

                    {((pendingUpload && !inProgress) && !uploadedFile) &&
                        <PendingPlaceholder>
                            <p>{pendingUpload.name}</p>        
                            <Button onClick={_onUpload}>Upload</Button>
                        </PendingPlaceholder>
                    }
                    {((pendingUpload && !inProgress) && uploadedFile) &&
                        <CoverImage src={`${process.env.REACT_APP_API_HOST}/${uploadedFile.src_folder}/${uploadedFile.sizes[2].file}`}/>
                    }
                </UploaderWrapper>
            }
        </React.Fragment>
    );
}

export default CoverUploader;