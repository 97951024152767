import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import AxiosClient from '../../AxiosClient';
import { ContentState, EditorState, convertFromHTML } from 'draft-js';
import { convertToHTML } from 'draft-convert';
import WrapperDiv from '../../WrapperDiv';
import RecipeForm from './RecipeForm';
import Toaster from '../../Toaster';
import moment from "moment"

const NUTRITION_BASE = process.env.REACT_APP_NUTRITION_BASE;

const RecipeFields = `
    id
    title
    excerpt
    method
    preptime
    difficulty
    is_pro
    rp_calories
    rp_fat
    rp_saturates
    rp_protein
    rp_carbohydrates
    rp_sugars
    rp_fibre
    rp_salt
    ingredients {
        id
        name
        rendered_name
        recipe_qty
        unit
        nf_calories
        nf_fat
        nf_saturates
        nf_protein
        nf_carbohydrates
        nf_sugars
        nf_fibre
        nf_salt
    }
    tags
    publishing_status
    scheduled_at
`;

const GET_RECIPE = `
    query($id: Int) {
        findRecipe(id: $id) {
            ${RecipeFields}
        }
        RecipeFindTags(id: $id)
    }
`;

const RecipeInput = `
    $id: Int
    $title: String!
    $method: String
    $excerpt: String
    $preptime: Int
    $difficulty: String
    $is_pro: Boolean
    $ingredients: [RecipeIngredientInput]
    $tags: [String]
    $featured_image: JSONObject
    $publishing_status: String
    $scheduled_at: String
`;

const SAVE_RECIPE = `
    mutation(${RecipeInput}) {
        saveRecipe(
            id: $id
            title: $title
            method: $method
            excerpt: $excerpt
            preptime: $preptime
            difficulty: $difficulty
            is_pro: $is_pro
            ingredients: $ingredients
            tags: $tags
            featured_image: $featured_image
            publishing_status: $publishing_status
            scheduled_at: $scheduled_at
        ) {
            ${RecipeFields}
        }
    }
`;

function RecipesEditor(props) {
    const [input, setInput] = useState({'ingredients': []});
    const [editorState, setEditorState] = useState(EditorState.createEmpty());

    /**
     *
     *
     * @memberof RecipesEditor
     */
    const save = (e) => {
        e.preventDefault();
        // prepare GraphQL query variables...
        let gqlVars = _.clone(input);
        // ...remove fields that are not part
        // of the GraphQL input schema
        gqlVars.ingredients.map((ingredient, i) => {
            return gqlVars.ingredients[i] = {
                id: ingredient.id,
                recipe_qty: parseInt(ingredient.recipe_qty * NUTRITION_BASE),
                rendered_name: ingredient.rendered_name
            };
        });
        // transform the pro/free switch value to boolean
        if(gqlVars['is_pro']) {
            const checked = gqlVars['is_pro'];
            gqlVars['is_pro'] = (checked === 'on' || checked === true) ? true : false;
        }
        // workflow
        gqlVars['scheduled_at'] = gqlVars['publishing_status'] === "scheduled" ? `${moment(gqlVars['scheduled_at']).format("YYYY-MM-DD")}` : null;

        AxiosClient.request({
            url: process.env.REACT_APP_GRAPHQL_URL,
            method: 'post',
            data: {
                query: SAVE_RECIPE,
                variables: gqlVars
            }
        }).then(res => {
            const data = res.data.data.saveRecipe;
            data.ingredients.map((ingredient, i) => {
                data.ingredients[i]['recipe_qty'] = ingredient.recipe_qty/NUTRITION_BASE;
            });
            // convert timestamp to date so that it can be used in the picker
            if(data['scheduled_at'])
                data['scheduled_at'] = moment(parseInt(data.scheduled_at)).toDate();
            
            setInput(data);
            // updated the state and show the toaster
            // - we update the state with response from the API
            // so that the nutritional info is updated accordingly
            _onUpdated();
        }).catch(err => {
            var msg;
            if(!err.response) {
                msg = "The changes couldn't be saved";
            } else {
                var data = err.response.data;
                msg = data['errors'] && data['errors'][0]['message'];
            }
            _onError(msg);
        });
    }

    /**
     *
     *
     * @memberof RecipesEditor
     */
    const _onUpdated = () => {
        Toaster.show({intent: 'success', icon: 'tick', message: 'Recipe updated.' });
    }

    /**
     *
     *
     * @memberof RecipesEditor
     */
    const _onError = (msg) => {
        Toaster.show({intent: 'danger', icon: 'error', message: msg || 'An error occurred.' });
    }

    /**
     *
     *
     * @param {*} e
     * @memberof RecipesEditor
     */
    const _onChange = (e) => {
        if(e.target) {
            let { name, value } = e.target;
            let object = {};
            object[name] = value;
            setInput(Object.assign({}, input, object));
        } else {
            setEditorState(e);
            setInput(Object.assign({}, input, {
                method: convertToHTML(e.getCurrentContent())
            }));
        }
    }

    /**
     *
     *
     * @memberof RecipesEditor
     */
    const _onChangeIngredient = (ingredient, action) => {
        let ingredients = input.ingredients || [];
        if(action === 'add') {
            // normalize quantity
            ingredient.recipe_qty = ingredient.qty/NUTRITION_BASE;
            ingredients.push(ingredient);
            setInput(Object.assign({}, input, {
                ingredients: ingredients
            }));
        }

        if(action === 'remove') {
            let indexToRemove = _.findIndex(ingredients, ['id', ingredient.id]);
            ingredients.splice(indexToRemove, 1);
            setInput(Object.assign({}, input, {
                ingredients: ingredients
            }));
        }
    }

    /**
     *
     *
     * @memberof RecipesEditor
     */
    const _onChangeIngredientQuantity = (e, ingredient) => {
        let { value } = e.target;
        let ingredientsList = input.ingredients;
        let indexToUpdate = _.findIndex(ingredientsList, ['id', ingredient.id]);
        ingredientsList[indexToUpdate].recipe_qty = value;
        setInput(Object.assign({}, input, {
            ingredients: ingredientsList
        }));
    }

    /**
     * 
     * @param {*} e 
     * @param {*} ingredient 
     */
    const _onChangeIngredientDisplayName = (e, ingredient) => {
        let { value } = e.target;
        let ingredientsList = input.ingredients; 
        let indexToUpdate = _.findIndex(ingredientsList, ['id', ingredient.id]);
        ingredientsList[indexToUpdate].rendered_name = value;
        setInput(Object.assign({}, input, {
            ingredients: ingredientsList
        }));       
    }

    /**
     *
     *
     * @memberof RecipesEditor
     */
    const _onRemoveFeaturedImage = () => {
        setInput(Object.assign({}, input, {
            'featured_image': {}
        }));
    }

    /**
     *
     *
     * @memberof RecipesEditor
     */
    const _onUpdateTags = (tags) => {
        setInput(Object.assign({}, input, {
            'tags': tags
        }));
    }

    /**
     *
     *
     * @memberof RecipesEditor
     */
    useEffect(() => {
        const recipeID = parseInt(props.match.params.id),
              isEdit = recipeID;

        if(isEdit) {
            AxiosClient.request({
                url: process.env.REACT_APP_GRAPHQL_URL,
                method: 'post',
                data: {
                    query: GET_RECIPE,
                    variables: {id: recipeID}
                }
            }).then(res => {
                const input = res.data.data.findRecipe;
                input['tags'] = res.data.data.RecipeFindTags;
                // normalize quantities
                input.ingredients.map((ingredient, i) => {
                    input.ingredients[i]['recipe_qty'] = ingredient.recipe_qty/NUTRITION_BASE;
                });
                // convert timestamp to date so that it can be used in the picker
                if(input['scheduled_at'])
                    input['scheduled_at'] = moment(parseInt(input.scheduled_at)).toDate();
                
                setInput(input);

                if(input.method && input.method.length > 1) {
                    const methodFromHTML = convertFromHTML(input.method);
                    const editorState = ContentState.createFromBlockArray(
                        methodFromHTML.contentBlocks,
                        methodFromHTML.entityMap
                    );
                    setEditorState(EditorState.createWithContent(editorState));
                }
            }).catch(err => {
                console.log(err);
            });
        }
    }, []);

    return(
        <WrapperDiv>
            <RecipeForm 
                input={input}
                editorState={editorState}
                onChange={ _onChange.bind(this) } 
                onChangeIngredient={_onChangeIngredient} 
                onChangeIngredientQuantity={_onChangeIngredientQuantity}
                onChangeIngredientDisplayName={_onChangeIngredientDisplayName}
                onRemoveFeaturedImage={_onRemoveFeaturedImage}
                onUpdateTags={_onUpdateTags}
                onSubmit={save}
            />
        </WrapperDiv>
    );
}

export default RecipesEditor;