import React from 'react';
import styled from'styled-components';
import { Redirect } from 'react-router-dom';
import { FormGroup, InputGroup, Button, Callout } from '@blueprintjs/core';
//import AxiosClient from '../AxiosClient';
import FetchClient from "../FetchClient"
import Auth from '../Auth';

const LoginForm = styled.form`
    display: block;
    background-color: #fff;
    max-width: 400px;
    padding: 40px;
    padding-top: 24px;
    margin: 10% auto;
`;

const FormHeader = styled.header`
    margin-bottom: 24px;

    h1 {
        font-size: 24px;
    }
`;

class PageSignin extends React.Component {
    state = {
        input: {},
        errors: null,
        isLoading: false,
    };

    _onChange = (e) => {
        let { name, value } = e.target;
        let object = {};
        object[name] = value;
        this.setState({ input: Object.assign({}, this.state.input, object) });
    }

    _onSubmit = (e) => {
        e.preventDefault();
        this.setState({isLoading: true, errors: null});

        var data =  {
            'email': this.state.input.uname,
            'password': this.state.input.upwd
        };

        FetchClient({
            method: 'post',
            data: data,
            url: `${process.env.REACT_APP_REST_HOST}/auth`
        })
        .then(res => {
            const { authToken, role } = res;
            // only admins can access the CMS
            if(role !== 'admin') {
                this.setState({errors: {'code': 'invalidRole', 'msg': 'You don\'t have permissions to access this page.'}});
            } else {
                // authorize user
                Auth.signin(authToken);
            }
        })
        .catch(err => {
            const res = err.response.data;
            this.setState({errors: {'code': res.error, 'msg': res.message}});
        })
        .then(() => { 
            setTimeout(() => {
                // if the user is authenticated successfully...
                if(Auth.isAuthenticated) {
                    // ...refresh the application to force the Apollo Client
                    // to update and get the user token
                    // - see ApolloClient component
                    window.location.reload();
                } else {
                    this.setState({isLoading: false});
                }
            }, 1000);
        });
    }

    componentDidMount() {
        // TODO
        // call backend to retrieve user profile and
        // if there is an auth cookie, double check the JWT token 
        // is still valid
    }

    render() {
        const { errors } = this.state;

        if(Auth.isAuthenticated())
            return <Redirect to='/home'/>;

        return(
            <LoginForm onSubmit={this._onSubmit}>
                <FormHeader>
                    <h1>Sign in</h1>
                    {errors &&
                        <Callout intent="danger" title="Error">
                            <p>We were unable to authenticate you for the following reasons:</p>
                            <p>{errors['code']} {errors['msg']}</p>
                        </Callout>
                    }
                </FormHeader>
                <FormGroup label="Email">
                    <InputGroup large type="email" name="uname" placeholder="jane@example.com" autoComplete="username" onChange={this._onChange}/>
                </FormGroup>
                <FormGroup label="Password">
                    <InputGroup large type="password" name="upwd" placeholder="Your password" autoComplete="current-password" onChange={this._onChange}/>
                </FormGroup>
                <Button large type="submit" intent="primary" text="Sign in" loading={this.state.isLoading}/>
            </LoginForm>
        );
    }
}

export default PageSignin;