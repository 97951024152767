import React from 'react';
import { Button, MenuItem } from '@blueprintjs/core';
import { Omnibar } from '@blueprintjs/select';
import { ApolloConsumer } from 'react-apollo';
import gql from 'graphql-tag';

const NUTRITION_BASE = process.env.REACT_APP_NUTRITION_BASE;

const FETCH_INGREDIENTS = gql`
    query($page: Int, $pageSize: Int, $searchKey: String, $searchFields: String) {
        listIngredients(page: $page, per_page: $pageSize, search_key: $searchKey, search_fields: $searchFields) {
            results {
                id
                name
                qty
                unit
                nf_calories
                nf_fat
                nf_saturates
                nf_protein
                nf_carbohydrates
                nf_sugars
                nf_fibre
                nf_salt
            }
            pagination {
                current
                total
            }
        }
    }
`;

class IngredientTypeahead extends React.Component {
    state = {
        isOpen: false,
        suggestions: []
    }

    _openOmnibar = () => {
        this.setState({isOpen: true});
    }

    _closeOmnibar = () => {
        this.setState({isOpen: false})
    }

    _renderSuggestions = (item, { handleClick, modifiers, query }) => {
        if (!modifiers.matchesPredicate) {
            return null;
        }
        //const text = `${film.rank}. ${film.title}`;
        return (
            <MenuItem
                active={modifiers.active}
                disabled={modifiers.disabled}
                label={`${item.name}, ${item.qty/NUTRITION_BASE} ${item.unit}`}
                key={item.id}
                onClick={handleClick}
                //text={highlightText(text, query)}
            />
        );
    }

    _filterSuggestions = (query, item) => {
        return (`${item.name}`.toLowerCase()).indexOf(query.toLowerCase()) >= 0;
    }

    /*
    _renderInputValue = item => {
        return item.name;
    }
    */

    _updateSuggestions = suggestions => {
        this.setState({suggestions});
    }

    _onItemSelect = item => {
        this._closeOmnibar();
        this.props.onChange(item, 'add');
    }

    render() {
        const { isOpen, suggestions } = this.state;

        return(
            <ApolloConsumer>
                {client => (
                    <React.Fragment>
                        <Button icon="search" text="Search ingredients" onClick={this._openOmnibar} />
                        <Omnibar
                            isOpen={isOpen}
                            itemPredicate={this._filterSuggestions}
                            itemRenderer={this._renderSuggestions}
                            items={suggestions}
                            popoverProps={{ 'minimal': true }}
                            onQueryChange={async (query) => {
                                const { data } = await client.query({
                                    'query': FETCH_INGREDIENTS,
                                    'variables': { page: 1, pageSize: 50, searchFields: 'name', searchKey: query }
                                });
                                this._updateSuggestions(data.listIngredients.results);
                            }}
                            onItemSelect={this._onItemSelect}
                            resetOnSelect={true}
                            onClose={this._closeOmnibar}
                        />
                    </React.Fragment>
                )}
            </ApolloConsumer>
        );
    }
}

export default IngredientTypeahead;