import React, { Component } from 'react';
import _ from 'lodash';
import WrapperDiv from '../../WrapperDiv';
import IngredientForm from './IngredientForm';
import Toaster from '../../Toaster';
import AxiosClient from '../../AxiosClient';

const NUTRITION_BASE = process.env.REACT_APP_NUTRITION_BASE;

const IngredientFields = `
    id
    name
    qty
    unit
    category
    nf_calories
    nf_fat
    nf_saturates
    nf_protein
    nf_carbohydrates
    nf_sugars
    nf_fibre
    nf_salt
`;

const GET_INGREDIENT = `
    query($id: Int) {
        findIngredient(id: $id) {
            ${IngredientFields}
        }
    }
`;

const IngredientInput = `
    $id: Int
    $name: String!
    $qty: Float!
    $unit: String!
    $category: String
    $nf_calories: Float!       
    $nf_fat: Float
    $nf_saturates: Float
    $nf_protein: Float
    $nf_carbohydrates: Float
    $nf_sugars: Float
    $nf_fibre: Float
    $nf_salt: Float
`;

const SAVE_INGREDIENT = `
    mutation(${IngredientInput}) {
        saveIngredient(
            id: $id
            name: $name
            qty: $qty
            unit: $unit
            category: $category
            nf_calories: $nf_calories        
            nf_fat: $nf_fat
            nf_saturates: $nf_saturates
            nf_protein: $nf_protein
            nf_carbohydrates: $nf_carbohydrates
            nf_sugars: $nf_sugars
            nf_fibre: $nf_fibre
            nf_salt: $nf_salt
        ) {
            ${IngredientFields}
        }
    }
`;

class PageIngredientEditor extends Component {
    state = {
        error: null,
        input: {}
    };

    _displayInput = (input) => {
        return Object.assign({}, input, {
            'qty': input.qty/NUTRITION_BASE,
            'nf_calories': input.nf_calories/NUTRITION_BASE,                
            'nf_fat': input.nf_fat/NUTRITION_BASE,
            'nf_saturates': input.nf_saturates/NUTRITION_BASE,
            'nf_protein': input.nf_protein/NUTRITION_BASE,
            'nf_carbohydrates': input.nf_carbohydrates/NUTRITION_BASE,
            'nf_sugars': input.nf_sugars/NUTRITION_BASE,
            'nf_fibre': input.nf_fibre/NUTRITION_BASE,
            'nf_salt': input.nf_salt/NUTRITION_BASE
        });
    }

    /**
     *
     *
     * @memberof PageIngredientEditor
     */
    save = (e) => {
        e.preventDefault();
        let gqlVars = _.clone(this.state.input);

        Object.keys(gqlVars).map(k => {
            if(k.indexOf('nf_') > -1) 
                gqlVars[k] = parseFloat(gqlVars[k]);
        });
        
        AxiosClient.request({
            url: process.env.REACT_APP_GRAPHQL_URL,
            method: 'post',
            data: {
                query: SAVE_INGREDIENT,
                variables: gqlVars
            }
        }).then(res => {
            const input = this._displayInput(res.data.data.saveIngredient);
            this.setState({input});
            this._onUpdated();
        }).catch(err => {
            var data = err.response.data;
            var msg = data['errors'] && data['errors'][0]['message'];
            this._onError(msg);
        });
    }
    
    _onUpdated = () => {
        Toaster.show({intent: 'success', icon: 'tick', message: 'Ingredient updated.' });
    }

    _onError = (msg) => {
        Toaster.show({intent: 'danger', icon: 'error', message: msg || 'An error occurred.' });
    }

    _onChange = e => {
        let { name, value } = e.target;        
        let object = {};
        object[name] = value;
        this.setState({ input: Object.assign({}, this.state.input, object) });
    }

    _onNutritionixSelect = (selection) => {
        var newInput = {
            'name': `${selection.food_name}, ${selection.serving_qty} ${selection.serving_unit}`,
            'nf_calories': selection.nf_calories,                
            'nf_fat': selection.nf_fat,
            'nf_saturates': selection.nf_saturates,
            'nf_protein': selection.nf_protein,
            'nf_carbohydrates': selection.nf_carbohydrates,
            'nf_sugars': selection.nf_sugars,
            'nf_fibre': selection.nf_fibre,
            'nf_salt': selection.nf_salt, 
            'qty': selection.serving_qty,
            'unit': selection.serving_unit
        };
        this.setState({ 'input': Object.assign({}, this.state.input, newInput) });
    } 

    componentDidMount() {
        const ingredientID = parseInt(this.props.match.params.id),
              isEdit = ingredientID;

        if(isEdit) {
            AxiosClient.request({
                url: process.env.REACT_APP_GRAPHQL_URL,
                method: 'post',
                data: {
                    query: GET_INGREDIENT,
                    variables: { 'id': ingredientID}
                }
            }).then(res => {
                const input = this._displayInput(res.data.data.findIngredient);
                this.setState({ input });
            }).catch(err => {
                console.log(err);
            });
        }
    }

    render() {
        return(
            <WrapperDiv>
                <IngredientForm 
                    { ...this.state }
                    onChange={this._onChange}
                    onNutritionixSelect={this._onNutritionixSelect} 
                    onSubmit={this.save}
                />
            </WrapperDiv>
        );
    }
}

export default PageIngredientEditor;