import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Button, FormGroup, ControlGroup, InputGroup, HTMLSelect, Checkbox, Classes, Colors, Alert } from '@blueprintjs/core';
import { DateInput } from "@blueprintjs/datetime";
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import PageHeader from '../PageHeader';
import { FormWrapper, FormBody, FormSidebar, FormActions } from '../../Forms';
import { IngredientTypeahead } from '../../Typeahead';
import RDIDisplayCard from '../../RDIDisplayCard';
import InlineNIDisplay from '../../InlineNIDisplay';
import CoverUploader from './CoverUploader';
import TagsSelector from '../../TagsSelector';
import NumericInputWrapper from "../../NumericInputWrapper";
import moment from "moment"

const EditorToolbarOpts = {
    options: [
        'inline', 
        'blockType', 
        'fontSize', 
        'list', 
        'textAlign', 
        'link', 
        'embedded', 
        'emoji', 
        'image', 
        'remove', 
        'history'
]};

const ListItemContainer = styled.div`
    border-bottom: 1px solid ${Colors.LIGHT_GRAY2};
    padding-bottom: 16px;
    margin-bottom: 16px;
`;

const TagsSelectorWidget = styled.div`
    .${Classes.POPOVER_TARGET} {
        width: 100%;
    }

    .${Classes.INPUT} {
        min-height: 80px;

        .${Classes.TAG_INPUT_VALUES} {
            display: inline;
        }
    }
`;

const DisplayNameWrapper = styled.div`
    display: block;
    max-width: 410px;
    margin-top: 8px;
`;

const RecipeForm = props => {
    const { input } = props;
    const [showAlert, setShowAlert] = useState(false);

    const _onScheduled = (date) => {
        props.onChange({ 'target': { 'name': 'scheduled_at', 'value': `${moment(date).format("YYYY-MM-DD")} 00:00` } });
    }

    const _onSubmit = (e) => {
        e.preventDefault();

        if(input.publishing_status === 'scheduled' || input.publishing_status === 'published') {
            setShowAlert(true);
        }
        else {
            props.onSubmit(e);
        }
    }

    const _onConfirmPublish = (e) => {
        setShowAlert(false);
        props.onSubmit(e);
    }

    const _onCancelPublish = () => {
        setShowAlert(false);
    }

    return(
        <React.Fragment>
            <PageHeader title={`${ props.isEdit ? 'Edit' : 'Add new'} recipe`} />
        
            <FormWrapper onSubmit={ _onSubmit }>
                <FormBody>

                    <FormGroup label="Title">
                        <InputGroup large name="title" placeholder="Falafel wrap" onChange={props.onChange} value={input['title'] || ''}/>
                    </FormGroup>
                    <FormGroup label="Method">
                        <Editor wrapperClassName="wysiwyg" editorClassName={Classes.INPUT} toolbar={EditorToolbarOpts} editorState={ props.editorState } onEditorStateChange={ props.onChange } />
                    </FormGroup>
                    <FormGroup label="Ingredients selection">
                        <IngredientTypeahead onChange={props.onChangeIngredient}/>
                    </FormGroup>
                    {input['ingredients'].map(ingredient => {
                        return(
                            <ListItemContainer key={ingredient.id}>
                                <ControlGroup> 
                                    <InputGroup large disabled name="ingredient" value={`${ingredient.name} (${ingredient.unit})`}/>
                                    <InputGroup large name="recipe_qty" value={ingredient.recipe_qty} onChange={e => {props.onChangeIngredientQuantity(e, ingredient)}}/>
                                    <Button large icon="trash" onClick={() => {props.onChangeIngredient(ingredient, 'remove')}}/>
                                </ControlGroup>
                                <DisplayNameWrapper>
                                    <FormGroup>
                                        <InputGroup name="rendered_name" onChange={e => {props.onChangeIngredientDisplayName(e, ingredient)}} value={ingredient.rendered_name} placeholder="Display name"/>
                                    </FormGroup>
                                </DisplayNameWrapper>
                                <InlineNIDisplay {...ingredient}/>
                            </ListItemContainer>
                        );
                    })}
                    <FormGroup label="Preptime (min)">
                        <NumericInputWrapper large name="preptime" placeholder="20" onChange={props.onChange} value={input['preptime'] || ''}/>
                    </FormGroup>
                    <FormGroup label="Difficulty">
                        <HTMLSelect large name="difficulty" onChange={props.onChange} value={input['difficulty'] || 'easy'}>
                            <option value="effortless">Effortless</option>
                            <option value="easy">Easy</option>
                            <option value="moderate">Moderate</option>
                            <option value="challenging">Challenging</option>
                        </HTMLSelect>
                    </FormGroup>
                    <FormGroup label="Free or pro">
                        <HTMLSelect large name="is_pro" value={input.is_pro} onChange={props.onChange}>
                            <option value={true}>Pro</option>
                            <option value={false}>Free</option>
                        </HTMLSelect>
                    </FormGroup>
                    <FormGroup label="Workflow">
                        <ControlGroup>
                        <HTMLSelect name="publishing_status" value={input.publishing_status} onChange={props.onChange}>
                            <option value="draft">Draft</option>
                            <option value="in_review">In review</option>
                            <option value="scheduled">Scheduled</option>
                            <option value="published">Published</option>
                        </HTMLSelect>
                        {input.publishing_status === "scheduled" &&
                            <DateInput 
                                formatDate={date => moment(date).format("YYYY-MM-DD")}
                                minDate={moment().toDate()}
                                onChange={(d) => _onScheduled(d)}
                                parseDate={str => moment(str, "YYYY-MM-DD").toDate()}
                                placeholder={"YYYY-MM-DD"}
                                value={moment(input.scheduled_at).toDate()}
                            />
                        }
                        </ControlGroup>
                    </FormGroup>
                    <FormActions>
                        <Button large type="submit" intent="primary">Save</Button>
                    </FormActions>
                </FormBody>

                <FormSidebar>
                    <div>
                        <h5>Featured image</h5>
                        <CoverUploader recipeId={input.id} onChange={props.onChange} onRemoveFeaturedImage={props.onRemoveFeaturedImage}/>
                    </div>
                    <div style={{marginTop: 40}}>
                        <h5>Recipe nutritional info</h5>
                        <RDIDisplayCard
                            label="Calories (Kcal)"
                            value={input['rp_calories']}
                            slug="calories"
                        />
                        <RDIDisplayCard
                            label="Fat (g)"
                            value={input['rp_fat']}
                            slug="fat"
                        />
                        <RDIDisplayCard
                            label="Saturates (g)"
                            value={input['rp_saturates']}
                            slug="saturates"
                        />
                        <RDIDisplayCard
                            label="Protein (g)"
                            value={input['rp_protein']}
                            slug="protein"
                        />
                        <RDIDisplayCard
                            label="Carbohydrates (g)"
                            value={input['rp_carbohydrates']}
                            slug="carbohydrates"
                        />
                        <RDIDisplayCard
                            label="Sugars (g)"
                            value={input['rp_sugars']}
                            slug="sugars"
                        />
                        <RDIDisplayCard
                            label="Fibre (g)"
                            value={input['rp_fibre']}
                            slug="fibre"
                        />
                        <RDIDisplayCard
                            label="Salt (g)"
                            value={input['rp_salt']}
                            slug="salt"
                        />
                    </div>
                    <div>
                        <h5>Tags</h5>
                        <TagsSelectorWidget>
                            <TagsSelector selection={input.tags} onChange={props.onUpdateTags}/>
                        </TagsSelectorWidget>
                    </div>
                </FormSidebar>
            </FormWrapper>

            <Alert
                cancelButtonText="Cancel"
                confirmButtonText="Publish"
                intent="primary"
                isOpen={showAlert}
                onCancel={_onCancelPublish}
                onConfirm={_onConfirmPublish}
            >
                <h4 style={{marginTop: 0}}>Publish recipe?</h4>
                <p>Are you sure you want to publish this recipe?</p>
            </Alert>
        </React.Fragment>
    );
};

export default RecipeForm;
