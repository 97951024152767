import React from 'react';
import styled from 'styled-components';

const Container = styled.ul`
    list-style: none;
    padding: 0;
    margin: 0;
`;
const Entry = styled.li`
    display: inline-block;
    font-size: 11px;
    color: #999;

    &:after {
        display: inline-block;
        content: '\\2022';
        margin: 0 4px;
    }

    &#nidisplay-salt:after {
        display: none;
    }
`;

// to avoid miscalculations with the nutritional info
// intake for each nutrient is saved as an INT multiplying
// the original value for 10000
// - see the backend in the save method for Ingredient
const evalNutrition = (value, normalize = true) => {
    if(value === 0 || isNaN(value)) return value;
    return normalize ? (value/10000).toFixed(2) : value.toFixed(2);
}

export default (props) => {

    var calories = props.calories || props.nf_calories || props.rp_calories || 'NA';
    var fat = props.fat || props.nf_fat || props.rp_fat || 'NA';
    var saturates = props.saturates || props.nf_saturates || props.rp_saturates || 'NA';
    var protein = props.protein || props.nf_protein || props.rp_protein || 'NA';
    var carbohydrates = props.carbohydrates || props.nf_carbohydrates || props.rp_carbohydrates || 'NA';
    var sugars = props.sugars || props.nf_sugars || props.rp_sugars || 'NA';
    var fibre = props.fibre || props.nf_fibre || props.rp_fibre || 'NA';
    var salt = props.salt || props.nf_salt || props.rp_salt || 'NA';

    var { normalize } = props; 

    return(
        <Container>
            <Entry id="nidisplay-calories">Calories: {evalNutrition(calories, normalize)}</Entry>
            <Entry id="nidisplay-fat">Fat: {evalNutrition(fat, normalize)}</Entry>
            <Entry id="nidisplay-saturates">Saturates: {evalNutrition(saturates, normalize)}</Entry>
            <Entry id="nidisplay-protein">Protein: {evalNutrition(protein, normalize)}</Entry>
            <Entry id="nidisplay-carbohydrates">Carbohydrates: {evalNutrition(carbohydrates, normalize)}</Entry>
            <Entry id="nidisplay-sugars">Sugars: {evalNutrition(sugars, normalize)}</Entry>
            <Entry id="nidisplay-fibre">Fibre: {evalNutrition(fibre, normalize)}</Entry>
            <Entry id="nidisplay-salt">Salt: {evalNutrition(salt, normalize)}</Entry>
        </Container>
    );
};