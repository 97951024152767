import React from 'react';
import { H3 } from '@blueprintjs/core';
import LinkWrapper from '../LinkWrapper';
import styled from 'styled-components';

const Header = styled.div`
    display: flex;
    padding-top: 24px;
    padding-bottom: 24px;
`;

export default props => {
    return(
        <Header>
            <H3 style={{flex: 2}}>{ props.title }</H3>
            { props.button && 
            <LinkWrapper to={props.button.href} icon={props.button.icon} text={props.button.text} />
            }
        </Header>
    );
};