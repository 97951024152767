import React, { Component } from 'react';
import styled from 'styled-components';
import AxiosClient from '../AxiosClient';
import { Button, Card, Icon, Overlay, InputGroup, Spinner, Classes } from '@blueprintjs/core';
import InlineNIDisplay from '../InlineNIDisplay';

const ActionAnchor = styled.a`
    display: block;
    font-size: 12px;
    padding: 8px 0;

    .${Classes.ICON} {
        float: left;
        margin-right: 5px;
    }
`;

const SearchPanel = styled(Overlay)`
    header {
        padding: 0 48px;
    }

    .${Classes.OVERLAY_CONTENT} {
        display: flex;
        flex-direction: column;
        top: 0;
        right: 0;
        bottom: 0;
        box-shadow: 0 0 0 1px rgba(16,22,26,.1), 0 4px 8px rgba(16,22,26,.2), 0 18px 46px 6px rgba(16,22,26,.2);
        background: #fff;
        width: 50vw;
        min-width: 500px;
        max-width: 800px;
        padding: 0;
        margin: 0;
    }
`;

const SearchResults = styled.div`
    padding: 24px 48px;

    h5 {
        font-size: 16px;
        margin: 0;
        margin-bottom: 8px;
    }

    button {
        margin-top: 8px;
    }

    .placeholder-text {
        display: block;
        text-align: center;
        opacity: .6;
        line-height: 240px;
        min-height: 240px;
    }
`;

class NutritionixSearch extends Component {
    state = {
        searchKeyword: '',
        searchResults: [],
        isLoading: false,
        isOpen: false
    };

    _togglePanel = () => {
        this.setState({isOpen: !this.state.isOpen});
    }

    _onChangeKeyword = e => {
        let { value } = e.target;        
        this.setState({searchKeyword: value});
    }

    _onSearch = () => {
        this.setState({searchResults: [], isLoading: true});

        AxiosClient.request({
            url: 'https://trackapi.nutritionix.com/v2/natural/nutrients',
            method: 'post',
            headers: {
                'x-app-id': process.env.REACT_APP_NX_APPID,
                'x-app-key': process.env.REACT_APP_NX_APIKEY
            },
            data:{query: this.state.searchKeyword}
        })
        .then(res => {
            var searchResults = res.data.foods.map(food => {
                // convert sodium (mg) to salt (g)
                let sodium = food.nf_sodium;
                food['nf_salt'] = ((sodium * 2.5)/1000);
                // prepare nutrients fields to make
                // according to format used in Vegly
                food['nf_fat'] = food['nf_total_fat'];
                food['nf_saturates'] = food['nf_saturated_fat'];
                food['nf_carbohydrates'] = food['nf_total_carbohydrate'];
                food['nf_fibre'] = food['nf_dietary_fiber'];
                food['nf_calories'] = food['nf_calories'];
                food['nf_protein'] = food['nf_protein'];
                food['nf_sugars'] = food['nf_sugars'];
                return food;
            });
            this.setState({searchResults: searchResults, isLoading: false});
        })
        .catch(err => {
            console.log(err);
        });
    }

    _onSelect = (food) => {
        this.setState({
            searchKeyword: '',
            searchResults: [],
            isLoading: false,
            isOpen: false
        });
        this.props.onSelect(food);
    }

    render() {
        let { searchKeyword, searchResults, isLoading, isOpen } = this.state;
        let hasKeyword = searchKeyword.length > 3;
        let showPlaceholder = searchResults.length < 1 && !isLoading;
        let showResults = searchResults.length >= 1;

        return(
            <React.Fragment>
                <ActionAnchor onClick={this._togglePanel}>
                    <Icon icon="search" iconSize={14}/>
                    Search Nutritionix
                </ActionAnchor>
                <SearchPanel isOpen={isOpen} className={Classes.OVERLAY_SCROLL_CONTAINER} onClose={this._togglePanel}>
                    <div>
                        <header>
                            <h3 className="title">Search Nutritionix database</h3>
                            <InputGroup large name="keyword" placeholder="100g of plum tomatoes" 
                                rightElement={
                                    <Button intent="primary" text="Search" disabled={!hasKeyword} onClick={this._onSearch}/>
                                } onChange={this._onChangeKeyword} value={searchKeyword}/>
                        </header>
                        <SearchResults>
                            {showPlaceholder &&
                            <p className="placeholder-text">Type an ingredient in the search bar above to search the Nutritionix database.</p>
                            }
                            {isLoading && 
                                <Spinner size={100}/>
                            }
                            {showResults && searchResults.map((entry, index) => (
                                <Card key={index}>
                                    <h5>{entry.food_name},&nbsp;{entry.serving_qty}&nbsp;{entry.serving_unit}</h5>
                                    <InlineNIDisplay {...entry} normalize={false}/>
                                    <Button text="Select" onClick={this._onSelect.bind(this, entry)}/>
                                </Card>
                            ))}
                        </SearchResults>
                    </div>
                </SearchPanel>
            </React.Fragment>
        );
    }
}

export default NutritionixSearch;