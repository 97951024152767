import React from 'react';
import { ButtonGroup, Button, HTMLSelect } from '@blueprintjs/core';

const Pagination = props => {
    const { currentPage, pageSize, totalItems } = props;
    const pages = Math.ceil(totalItems/pageSize);
    const cursor = typeof currentPage === 'string' ? parseInt(currentPage, 10) : currentPage;
    const prevPage = cursor-1;
    const nextPage = cursor+1;

    let menuItems = [];
    for(var i=1; i < pages+1; i++) {
        menuItems.push(<option key={i} value={i}>{i}</option>);
    }
    const pagesMenu = <HTMLSelect minimal value={currentPage} onChange={(evt) => props.onChange(parseInt(evt.currentTarget.value), pageSize)}>{menuItems}</HTMLSelect>

    return(
        <ButtonGroup>
            <Button icon="chevron-left" onClick={props.onChange.bind(null, prevPage, pageSize)} disabled={prevPage < 1}/>
            <Button icon="chevron-right" onClick={props.onChange.bind(null, nextPage, pageSize)} disabled={pages <= 1 || nextPage > pages}/>
            <div style={{marginLeft: 16}}>Page {pagesMenu} of {pages || 1}</div>
        </ButtonGroup>
    );
}

export default Pagination;