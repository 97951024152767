import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Redirect } from 'react-router-dom';
import { ApolloProvider } from 'react-apollo';
import ApolloClient from './components/ApolloClient';
import Auth from './components/Auth';
import Header from './components/Header';
import { 
	PageSignin,
	PageSignout,
	PageHome,
	PageIngredients,
	PageIngredientsEditor,
	PageRecipes,
	PageRecipesEditor,
	PagePlans,
	PagePlansEditor
} from './components/Pages';

import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
	body {
		background-color: #F4F4F8;
	}

	.wysiwyg {
		&__editor {
			border: 1px solid #A7B6C2;
			background-color: #EBF1F5;
			padding-left: 16px;
			padding-right: 16px;
		}
	}

	.bp3-select-popover .bp3-menu {
		max-height: 300px;
		overflow: auto;
	}

	.bp3-omnibar {
		filter: blur(0);
		top: 20vh;
		left: calc(50% - 250px);
		box-shadow: 0 0 0 1px rgba(16,22,26,.1),0 4px 8px rgba(16,22,26,.2),0 18px 46px 6px rgba(16,22,26,.2);
		width: 500px;
	}

	.bp3-omnibar, .bp3-popover {
		.bp3-menu:empty { display: none }
	}
`;



const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route { ...rest } render={props => (
        Auth.isAuthenticated() ? (
            <Component { ...props } />
        ) : (
            <Redirect to={{ pathname: '/', state: { from: props.location }}} />
        )
    ) } />
);

class App extends Component {
	render() {
		return (
			<Router>
				<GlobalStyle />
				<div style={{ minHeight: '100%' }}>
					<Header/>
					<Route exact path="/" component={ PageSignin } />
					<Route exact path="/signout" component={ PageSignout } />
					<PrivateRoute path="/home" component={ PageHome } />
					<PrivateRoute exact path="/ingredients" component={ PageIngredients } />
					<PrivateRoute path="/ingredients/editor/:id?" component={ PageIngredientsEditor } />
					<PrivateRoute exact path="/recipes" component={ PageRecipes } />
					<PrivateRoute path="/recipes/editor/:id?" component={ PageRecipesEditor } />					
					<PrivateRoute exact path="/plans" component={ PagePlans } />
					<PrivateRoute path="/plans/editor/:id?" component={ PagePlansEditor } />
				</div>
        	</Router>
		);
	}
}

const ApolloApp = () => {
    return(
        <ApolloProvider client={ ApolloClient }>
            <App />
        </ApolloProvider>
    );
}

export default ApolloApp;
