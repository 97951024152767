import React from 'react';
import styled from 'styled-components';
import { Card, H5 } from '@blueprintjs/core';
import WrapperDiv from '../WrapperDiv';
import PageHeader from './PageHeader';
import LinkWrapper from '../LinkWrapper';

const DashboardWrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 24px;
`;

const PageHome = props => {
    return(
        <WrapperDiv>
            <PageHeader title="Homepage"/>
            <DashboardWrapper>
                <Card>
                    <H5>Ingredients</H5>
                    <p>Manage ingredients</p>
                    <LinkWrapper to="/ingredients" text="Go to ingredients" />
                </Card>
                <Card>
                    <H5>Recipes</H5>
                    <p>Manage recipes</p>
                    <LinkWrapper to="/recipes" text="Go to recipes" />
                </Card>
                <Card>
                    <H5>Plans</H5>
                    <p>Manage plans</p>
                    <LinkWrapper to="/plans" text="Go to plans" />
                </Card>
            </DashboardWrapper>
        </WrapperDiv>
    );
};

export default PageHome;