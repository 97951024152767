var today = new Date();
    today.setDate(today.getDate() + 30);

// TODO add security on cookie for production

const Auth = {
    isAuthenticated: () => {
        let authCookie = document.cookie.replace(/(?:(?:^|.*;\s*)_vy_auth_admin\s*=\s*([^;]*).*$)|^.*$/, "$1"); 
        return authCookie || false;
    },

    signin: (token) => {
        // TODO add secure flag and domain protection for production
        let cookie = `_vy_auth_admin=${token}; path=/; domain=; expires=${today.toUTCString()};`;
        document.cookie = cookie;
    },
    
    signout: () => {
        let cookie = `_vy_auth_admin=; path=/; domain=; expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
        document.cookie = cookie;
    }
};

export default Auth;