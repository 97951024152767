import ApolloClient from 'apollo-boost';
import Auth from '../Auth';

const token = Auth.isAuthenticated();
  
export default new ApolloClient({
    uri: process.env.REACT_APP_GRAPHQL_URL,
    headers: {
        'access-token': token ? token : ""
    }
});