import React from 'react';
import styled from 'styled-components';
import { Button, Colors } from '@blueprintjs/core';

import RDIDisplayCard from '../../RDIDisplayCard';

const SlideoutPanel = styled.div`
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;

    & > div {
        display: flex;
        flex-direction: column;
        background-color: #fff;
        border-top: 0;
        border-bottom: 0;
        border-right: 0;
        top: 0;
        right: 0;
        bottom: 0;
        margin: 0;
        box-shadow: 0 0 0 1px rgba(16,22,26,.1),0 4px 8px rgba(16,22,26,.2),0 18px 46px 6px rgba(16,22,26,.2);
        width: 30vw;
        min-width: 230px;
        max-width: 360px;
        height: 100%;
        padding-top: 0;

        &:focus {
            outline: 0;
        }
    }
`;

const Widget = styled.div`
    border-top: 1px solid ${Colors.LIGHT_GRAY2};
`;

const WidgetHeader = styled.header`
    display: flex;
    padding: 16px 24px;
    padding-bottom: 0;

    .widget-title {
        margin: 0;
        margin-top: 8px;
        margin-bottom: 8px;
    }

    p { margin-left: auto }
`;

const WidgetBody = styled.div`
    padding: 24px;
`;

export default (props) => {
    const { selectedList, nutrients } = props.listNutrients;
    const { workflowPanel } = props;

    return(
        <SlideoutPanel>
            <div>
                <Widget>
                    <WidgetHeader>
                        <h5 className="widget-title">Plan settings</h5>
                    </WidgetHeader>
                    <WidgetBody>
                        { workflowPanel }
                    </WidgetBody>
                </Widget>
                {selectedList && 
                    <Widget>
                        <WidgetHeader>
                            <h5 className="widget-title">Nutritional info ({selectedList})</h5>
                            <p><Button minimal icon="small-cross" onClick={props.onHideNutrients.bind(this, null)}/></p>
                        </WidgetHeader>
                        <WidgetBody>
                            <RDIDisplayCard
                                label="Calories (Kcal)"
                                value={nutrients['rp_calories']}
                                slug="calories"
                            />
                            <RDIDisplayCard
                                label="Fat (g)"
                                value={nutrients['rp_fat']}
                                slug="fat"
                            />
                            <RDIDisplayCard
                                label="Saturates (g)"
                                value={nutrients['rp_saturates']}
                                slug="saturates"
                            />
                            <RDIDisplayCard
                                label="Protein (g)"
                                value={nutrients['rp_protein']}
                                slug="protein"
                            />
                            <RDIDisplayCard
                                label="Carbohydrates (g)"
                                value={nutrients['rp_carbohydrates']}
                                slug="carbohydrates"
                            />
                            <RDIDisplayCard
                                label="Sugars (g)"
                                value={nutrients['rp_sugars']}
                                slug="sugars"
                            />
                            <RDIDisplayCard
                                label="Fibre (g)"
                                value={nutrients['rp_fibre']}
                                slug="fibre"
                            />
                            <RDIDisplayCard
                                label="Salt (g)"
                                value={nutrients['rp_salt']}
                                slug="salt"
                            />
                        </WidgetBody>
                    </Widget>
                }
            </div>
        </SlideoutPanel>
    );
};