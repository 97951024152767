import React, { Component } from 'react';
import styled from 'styled-components';
import { Alert, Button, Dialog, FormGroup, HTMLSelect, H5, EditableText, Classes} from '@blueprintjs/core';
import { DateInput } from "@blueprintjs/datetime";
import { RecipeSuggest } from '../../Typeahead';
import Toaster from '../../Toaster';
import ListContainer from './ListContainer';
import SlideoutPanel from './SlideoutPanel';
import AxiosClient from '../../AxiosClient';
import _ from 'lodash';
import moment from 'moment';

const PlanInput = `
    $id: Int
    $title: String
    $rendered_title: String
    $is_pro: Boolean
    $menus: [[PlanRecipeInput]]
`;

const SAVE_PLAN = `
    mutation(${PlanInput}) {
        savePlan(
            id: $id
            title: $title
            rendered_title: $rendered_title
            is_pro: $is_pro
            menus: $menus
        ) {
            id
            title
            created_at
            updated_at
            menus {
                id
                title
                meal
                thumb
                rp_calories
                rp_fat
                rp_saturates
                rp_protein
                rp_carbohydrates
                rp_sugars
                rp_fibre
                rp_salt
            }
        }
    }
`;

const UPDATE_WORKFLOW = `
    mutation($id: Int, $status: String, $scheduled_at: String) {
        PlanUpdateWorkflow(
            id: $id
            status: $status
            scheduled_at: $scheduled_at
        ) {
            status
            scheduled_at
        }
    }
`;

const PageWrapper = styled.div`
    background-color: #F4F4F8;
    position: relative;
`;

const PageBody = styled.div`
    display: grid;
    height: 95vh;
    padding-top: 8px;
`;

const PageHeader = styled.div`
    padding-top: 24px;
    margin-left: 8px;
    margin-right: 8px;

    .version-info {
        padding-top: 8px;
    }

    .version-info span {
        opacity: .6;
    }
`;

const Lists = styled.div`
    display: flex;
    overflow-x: auto;
    margin-right: 28vw;

    > * {
        flex: 0 0 auto;
        margin-left: 8px;
    }

    &::after {
        content: '';
        flex: 0 0 8px;
    }
`;

const PlaceholderListContainer = styled.div``;

const DialogWrapper = styled(Dialog)`

    .${Classes.POPOVER_TARGET} {
        width: 100%
    }

    .${Classes.DIALOG_FOOTER_ACTIONS} {
        justify-content: space-between;
        button:first-child { margin-left: 0};
    }
`;

class PlanBoardEditor extends Component {
    state = {
        planId: this.props.id,
        planTitle: this.props.title,
        planRenderedTitle: this.props.renderedTitle,
        planWorkflow: this.props.workflow,
        // card create/edit dialog
        dialogOpts: {
            isOpen: false,
            canOutsideClickClose: true,
            title: false,
            dialogContext: false
        },
        planEntries: this.props.planEntries || [[]],
        loadListNutrients: false,
        showAlert: false
    };

    /**
     *
     *
     * @memberof PlanBoardEditor
     */
    save = () => {
        // remove UI only keys
        var menus = _.clone(this.state.planEntries);
        menus.map((menu, i) => {
            return menu.map((recipe, z) => {
                return menus[i][z] = Object.keys({id: null, meal: null}).reduce((obj, key) => {
                    obj[key] = recipe[key];
                    return obj;
                }, {})
            });
        });
        // prepare GraphQL query variables
        const gqlVars = {
            'title': this.state.planTitle || `Draft ${moment().format("MMM Do, YYYY")}`,
            'rendered_title': this.state.planRenderedTitle,
            'menus': menus
        };
        if(this.state.planId !== null)
            gqlVars['id'] = this.state.planId;

        AxiosClient.request({
            url: process.env.REACT_APP_GRAPHQL_URL,
            method: 'post',
            data: {
                query: SAVE_PLAN,
                variables: gqlVars
            }
        }).then(res => {
            let data = res.data.data.savePlan;
            // TODO UPDATE URL WITH ID OF SAVED PLAN 
            // IF NOT DONE ALREADY
            this.setState({
                planId: data.id,
                // update recipe entries again so thatn utritional info 
                // for the new added item is shown
                planEntries: data.menus 
            });
            Toaster.show({intent: 'success', icon: 'tick', message: 'Plan updated'});
        })
        .catch(err => {
            console.log(err);
            Toaster.show({intent: 'danger', icon: 'error', message: err || 'An error occurred'});
        });
    }

    /**
     *
     *
     * @memberof PlanBoardEditor
     */
    _onEditTitle = (planTitle) => {
        this.setState({ planTitle });
    }

    /**
     *
     *
     * @memberof PlanBoardEditor
     */
    _onConfirmTitle = () => {
        this.save();
    }

    /**
     *
     *
     * @memberof PlanBoardEditor
     */
    _onEditRenderedTitle = (planRenderedTitle) => {
        this.setState({ planRenderedTitle });
    }

    /**
     *
     *
     * @memberof PlanBoardEditor
     */
    _onConfirmRenderedTitle = () => {
        this.save();
    }

    /**
     *
     *
     * @memberof PlanBoardEditor
     */
    _onAddEmptyList = () => {
        let planEntries = this.state.planEntries;
        planEntries.push([]);
        this.setState({ planEntries });
    }

    /**
     *
     *
     * @memberof PlanBoardEditor
     */
    _onOpenModal = (listIndex, card = false) => {
        let updatedDialogContext = Object.assign({}, this.state.dialogContext, {
            'listIndex': listIndex,
            'meal': 'breakfast' // default value
        });
        if(card) {
            updatedDialogContext['meal'] = card.card.meal;
            updatedDialogContext['recipe'] = {
                'id': card.card.id,
                'title': card.card.title
            };
            updatedDialogContext['cardIndex'] = card.index;
        }
        let updatedOpts = Object.assign({}, this.state.dialogOpts, {
            'isOpen': true,
            'dialogContext': updatedDialogContext
        });
        this.setState({dialogOpts: updatedOpts});
    }

    /**
     *
     *
     * @memberof PlanBoardEditor
     */
    _onCloseModal = () => {
        let updatedOpts = Object.assign({}, this.state.dialogOpts, {
            'isOpen': false
        });
        this.setState({dialogOpts: updatedOpts});
    }

    /**
     *
     *
     * @memberof PlanBoardEditor
     */
    _onSelectDialogRecipe = (selectedRecipe) => {
        let updatedDialogContext = Object.assign({}, this.state.dialogOpts.dialogContext, {
            'recipe': selectedRecipe
        });
        let updatedOpts = Object.assign({}, this.state.dialogOpts, {
            'dialogContext': updatedDialogContext
        });
        this.setState({dialogOpts: updatedOpts});
    }

    /**
     *
     *
     * @memberof PlanBoardEditor
     */
    _onSelectDialogMeal = (evt) => {
        let selectedMeal = evt.currentTarget.value;
        let updatedDialogContext = Object.assign({}, this.state.dialogOpts.dialogContext, {
            'meal': selectedMeal
        });
        let updatedOpts = Object.assign({}, this.state.dialogOpts, {
            'dialogContext': updatedDialogContext
        });
        this.setState({dialogOpts: updatedOpts});
    }

    /**
     *
     *
     * @memberof PlanBoardEditor
     */
    _onAddCard = () => {
        const { planEntries, dialogOpts } = this.state;
        const saveOperation = dialogOpts.dialogContext;
        const entry = {
            'id': saveOperation.recipe.id,
            'title': saveOperation.recipe.title,
            'meal': saveOperation.meal
        };
        if(!(saveOperation.cardIndex > -1)) {
            // add new entry
            planEntries[saveOperation.listIndex].push(entry);
        } else {
            // replace old entry
            planEntries[saveOperation.listIndex][saveOperation.cardIndex] = entry;
        }
        this.setState({ 
            'planEntries': planEntries,
            'dialogOpts': Object.assign({}, this.state.dialogOpts, { isOpen: false, dialogContext: false }) 
        });
        // autosave
        setTimeout(this.save, 500);
    }

    /**
     *
     *
     * @memberof PlanBoardEditor
     */
    _onDeleteCard = () => {
        const { planEntries, dialogOpts } = this.state;
        const deleteOperation = dialogOpts.dialogContext;
        planEntries[deleteOperation.listIndex].splice(deleteOperation.cardIndex, 1);
        this.setState({ 
            'planEntries': planEntries,
            'dialogOpts': Object.assign({}, this.state.dialogOpts, { isOpen: false, dialogContext: false }) 
        });
        // autosave
        setTimeout(this.save, 500);
    }

    /**
     * Load a list's nutrients information in the side panel
     *
     * @memberof PlanBoardEditor
     */
    _onToggleListNutrients = (selectedList, cards) => {
        // if there is no selected list, hide nutrients
        if(!selectedList) {
            return this.setState({'loadListNutrients': false});
        }

        var nutrients = {};
        cards.map(card => {
            return Object.entries(card).map(entry => {
                var [ key, value ] = entry;
                if(key.indexOf('rp_') > -1) {
                    nutrients[key] ? nutrients[key] += value : nutrients[key] = value;
                }
                return true;
            });
        });
        setTimeout(this.setState({
            'loadListNutrients': {selectedList, nutrients}
        }), 1000);
    }

    /**
     *
     *
     * @memberof PlanBoardEditor
     */
    _onUpdateWorkflow = () => {
        AxiosClient.request({
            url: process.env.REACT_APP_GRAPHQL_URL,
            method: 'post',
            data: {
                query: UPDATE_WORKFLOW,
                variables: {
                    'id': this.state.planId,
                    'status': this.state.planWorkflow['status'],
                    'scheduled_at': this.state.planWorkflow['status'] === "scheduled" ? this.state.planWorkflow['scheduled_at'] : null,
                }
            }
        }).then(res => {
            //let data = res.data.data.PlanUpdateWorkflow;
            Toaster.show({intent: 'success', icon: 'tick', message: 'Plan updated'});
        })
        .catch(err => {
            console.log(err);
            Toaster.show({intent: 'danger', icon: 'error', message: err || 'An error occurred'});
        });
    }

    _onShowConfirmation = () => {
        if(this.state.planWorkflow['status'] === 'scheduled' || this.state.planWorkflow['status'] === 'published') {
            this.setState({'showAlert': true});
        } else {
            this._onUpdateWorkflow();
        }
    }

    _onConfirmPublish = (e) => {
        this.setState({'showAlert': false});
        this._onUpdateWorkflow();
    }

    _onCancelPublish = () => {
        this.setState({'showAlert': false});
    }

    render() {
        const dialogContext = this.state.dialogOpts.dialogContext;
            
        return(
            <PageWrapper>
                <PageHeader>
                    <H5>
                        <EditableText placeholder="Title (internal)" value={this.state.planTitle} onChange={this._onEditTitle} onConfirm={this._onConfirmTitle}/>
                    </H5>
                    <EditableText placeholder="Label" value={this.state.planRenderedTitle} onChange={this._onEditRenderedTitle} onConfirm={this._onConfirmRenderedTitle}/>
                </PageHeader>
                <PageBody>
                    <Lists>
                        {this.state.planEntries.map((plan, index) => (
                            <ListContainer key={index} listIndex={index} cards={plan} onEdit={this._onOpenModal} onAdd={this._onOpenModal} onShowNutrients={this._onToggleListNutrients}/>
                        ))}
                        <PlaceholderListContainer>
                            <Button onClick={this._onAddEmptyList}>Add another day plan</Button>
                        </PlaceholderListContainer>
                    </Lists>
                    <DialogWrapper 
                        onClose={this._onCloseModal}
                        {...this.state.dialogOpts}
                        title={dialogContext['recipe'] ? `Edit meal: ${dialogContext['recipe'].title}` : "Add a new meal"}
                    >
                        <div className={Classes.DIALOG_BODY}>
                            <FormGroup label="Recipe">
                                <RecipeSuggest onChange={this._onSelectDialogRecipe}/>
                            </FormGroup>
                            <FormGroup label="Meal">
                                <HTMLSelect name="meal" onChange={this._onSelectDialogMeal} value={dialogContext['meal']}>
                                    <option value="breakfast">Breakfast</option>
                                    <option value="lunch">Lunch</option>
                                    <option value="dinner">Dinner</option>
                                    <option value="snack">Snack</option>
                                </HTMLSelect>
                            </FormGroup>
                        </div>
                        <div className={Classes.DIALOG_FOOTER}>
                            <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                                <Button intent="danger" disabled={!(dialogContext['cardIndex'] > -1)} onClick={this._onDeleteCard}>Delete</Button>
                                <Button intent="success" onClick={this._onAddCard}>Save</Button>
                            </div>
                        </div>
                    </DialogWrapper>
                </PageBody>
                <SlideoutPanel 
                    listNutrients={this.state.loadListNutrients} 
                    onHideNutrients={this._onToggleListNutrients}
                    workflowPanel={
                        <div>
                            <FormGroup>
                                <HTMLSelect name="published_status" value={this.state.planWorkflow.status} onChange={evt => this.setState({ planWorkflow: Object.assign(this.state.planWorkflow, {}, {'status': evt.currentTarget.value}) })}>
                                    <option value="draft">Draft</option>
                                    <option value="in_review">In review</option>
                                    <option value="scheduled">Scheduled</option>
                                    <option value="published">Published</option>
                                </HTMLSelect>
                            </FormGroup>
                            {this.state.planWorkflow.status === "scheduled" &&
                                <FormGroup>
                                    <DateInput 
                                        formatDate={date => moment(date).format("YYYY-MM-DD")}
                                        minDate={moment().toDate()}
                                        onChange={(d) => this.setState({ planWorkflow: Object.assign(this.state.planWorkflow, {}, {'scheduled_at': moment(d).format("YYYY-MM-DD")}) })}
                                        parseDate={str => moment(str, "YYYY-MM-DD").toDate()}
                                        placeholder={"YYYY-MM-DD"}
                                        value={this.state.planWorkflow.status === "scheduled" ? moment(this.state.planWorkflow.scheduled_at).toDate() : null}
                                    />
                                </FormGroup>
                            }
                            <Button intent="primary" onClick={this._onShowConfirmation}>Update</Button>
                        </div>
                    }
                />
                <Alert
                    cancelButtonText="Cancel"
                    confirmButtonText="Publish"
                    intent="primary"
                    isOpen={this.state.showAlert}
                    onCancel={this._onCancelPublish}
                    onConfirm={this._onConfirmPublish}
                >
                    <h4 style={{marginTop: 0}}>Publish plan?</h4>
                    <p>Are you sure you want to publish this plan?</p>
                </Alert>
            </PageWrapper>       
        );
    }
}

export default PlanBoardEditor;