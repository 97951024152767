import React, { Component } from 'react';
import AxiosClient from '../../AxiosClient';
import PlanBoardEditor from './PlanBoardEditor';

const GET_PLAN = `
    query($id: Int) {
        findPlan(id: $id) {
            id
            title
            rendered_title
            publishing_status 
            scheduled_at
            menus {
                id
                title
                meal
                thumb
                rp_calories
                rp_fat
                rp_saturates
                rp_protein
                rp_carbohydrates
                rp_sugars
                rp_fibre
                rp_salt
            }
            updated_at
        }
    }
`;

class PagePlansEditor extends Component {
    state = {
        isLoading: true,
        input: []
    }

    componentDidMount() {
        const planID = parseInt(this.props.match.params.id),
              isEdit = planID;

        if(isEdit) {
            AxiosClient.request({
                url: process.env.REACT_APP_GRAPHQL_URL,
                method: 'post',
                data: {
                    query: GET_PLAN,
                    variables: {id: planID}
                }
            }).then(res => {
                const input = res.data.data.findPlan;
                this.setState({
                    isLoading: false,
                    input: input
                });
            }).catch(err => {
                console.log(err);
            });
        } else {
            this.setState({isLoading: false});
        }
    }

    render() {
        var { isLoading, input } = this.state;

        return !isLoading && <PlanBoardEditor
            id={input.id}
            title={input.title}
            renderedTitle={input.rendered_title}
            workflow={{ 'status': input.publishing_status, 'scheduled_at': parseInt(input.scheduled_at) /* this is a timestamp */ }}
            planEntries={input.menus}
        />;
    }
}

export default PagePlansEditor;