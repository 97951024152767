import React from 'react';
import styled from 'styled-components';
import { Button, Card, Tag } from '@blueprintjs/core';
import InlineNIDisplay from '../../InlineNIDisplay';

const List = styled.div`
    width: 300px;
    height: calc(100% - 8px - 17px);

    > * {
        background-color: #E4E4E4;
        padding: 0 8px;
    }
`;

const ListHeader = styled.header`
    display: flex;
    height: 40px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    padding-top: 8px;
    padding-bottom: 8px;

    .list-title {
        padding-top: 5px;
        padding-bottom: 5px;
    }

    button {
        margin-left: auto;
    }
`;

const ListFooter = styled.footer`
    height: 40px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    padding-top: 8px;
    padding-bottom: 8px;
`;

const CardsContainer = styled.ul`
    list-style: none;
    max-height: calc(100% - 40px - 40px);
    margin: 0;
    overflow-y: auto;

    .list-card {
        padding: 16px;
        margin-top: 4px;
        margin-bottom: 8px;
    }
`;

const CardHeader = styled.div`
    display: flex;
    flex: 80px auto;
    margin-bottom: 8px;
`;

const CardThumbnail = styled.span`
    width: 80px;
    height: 80px;
    margin-right: 8px;

    img {
        width: 80px;
        height: 80px;
    }
`;

const ListContainer = props => {
    const listTitle = `Day ${props.listIndex + 1}`;

    return(
        <List>
            <ListHeader>
                <p className="list-title">{listTitle}</p>
                <Button minimal icon="search-template" onClick={props.onShowNutrients.bind(this, listTitle, props.cards)}/>
            </ListHeader>
            <CardsContainer>
                {props.cards.map((card, index) => (
                    <Card key={card.id} className={"list-card"} elevation={1} interactive={true} onClick={props.onEdit.bind(this, props.listIndex, {index, card})}>
                        <CardHeader>
                            <CardThumbnail>
                                <img src={`${process.env.REACT_APP_API_HOST}/${card.thumb}`} alt={card.title}/>
                            </CardThumbnail>
                            <div>
                                <p>{card.title}</p>
                                <Tag intent={"primary"} style={{marginBottom: 8}}>{card.meal}</Tag>
                            </div>
                        </CardHeader>
                        <InlineNIDisplay {...card}/>
                    </Card>
                ))}
            </CardsContainer>
            <ListFooter>
                <Button minimal onClick={props.onAdd.bind(this, props.listIndex, null)}>Add recipe</Button>
            </ListFooter>
        </List>
    );
};

export default ListContainer;