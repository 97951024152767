import React from 'react';
import { Button, FormGroup, InputGroup, ControlGroup, HTMLSelect } from '@blueprintjs/core';
import PageHeader from '../PageHeader';
import NumericInputWrapper from '../../NumericInputWrapper';
import { FormWrapper, FormBody, FormSidebar } from '../../Forms';
import { NutritionixSearch } from '../../Typeahead';
import _ from "lodash";

const aisles = [
    'Bakery',
    'Frozen',
    'Deli',
    'Cans',
    'Food cupboard',
    'Dairy',
    'Condiments and sauces',
    'Fresh vegetables',
    'Spices',
    'Chilled pastry',
    'Snacks',
    'Cereals',
    'Fresh herbs',
    'Fresh fruit',
    'Eggs',
    'Baking',
    'Drinks'
];

const units = [
    'kg',
    'g',
    'l',
    'ml',
    'tsp',
    'tbsp',
    'pinch',
    'medium (3" dia)',
    'medium',
    'medium eggplant',
    'small',
    'fruit',
    'fruit, without skin and seed',
    'medium bagel (3-1/2',
    'medium bagel (3-1/2" to 4" dia)',
    'medium (7" to 7-7/8" long)',
    'leaves',
    'leaf',
    'cup',
    'beet',
    'pepper',
    'hamburger bun',
    'piece',
    'carrot',
    'dash',
    'stalk',
    'slice',
    'cherry',
    'teaspoon',
    'pancakes',
    'pastry',
    'cupcake',
    'eclair',
    'medium muffin',
    'roll',
    'individual loaf',
    'clove',
    'medium crumpet',
    'doughnut, medium (approx 3-3/4" dia)',
    'muffin',
    'patty (approx 2-1/4" dia)',
    'bulb',
    'medium (2-1/4" dia)',
    'lolly',
    'melon (6" - 7" dia)',
    'head',
    'gherkin',
    'bar (1 oz)',
    'fruit (3-3/4" dia)',
    'heaped teaspoons',
    'bun',
    'hot dog bun',
    'fruit (2" dia)',
    'large',
    'fruit (2-3/8',
    'fruit (2-3/8" dia)',
    'fruit (2',
    'lime',
    'patty',
    'pie',
    'sausage',
    'sausages',
    'meringue',
    'biscuit',
    'cup without sauce',
    'large (2-3/4" dia)',
    'sheet',
    'serving 2 TBSP',
    'oatcake',
    'fruit (2-7/8" dia)',
    'fruit (2-1/2" dia)',
    'pita, large (6-1/2" dia)',
    'fruit (2-1/8" dia)',
    'mushrrom cap',
    'mushroom cap',
    'mushrooms',
    'fillet',
    'escalope',
    'steak',
    'Quorn Roast',
    'cake',
    'slice, regular',
    'sandwich roll',
    'shallot',
    'tortilla',
    'medium (4-1/8" long)',
    'pod',
    'medium (approx 5',
    'medium (approx 5" dia)',
    'medium whole (2-3/5',
    'medium whole (2-3/5" dia)',
    'medium whole',
    'can',
    'cube',
    'link',
    'puddings',
    'waffle, round (7" dia)',
    'cups',
    'litre',
    'tortilla 1 serving',
    'slices',
    'tortilla (approx 10" dia)',
    'unit'
];

const IngredientForm = props => {

    return(
        <React.Fragment>
            <PageHeader title={`${ props.input.id ? 'Edit' : 'Add new'} ingredient`} />

            <FormWrapper onSubmit={ props.onSubmit }>
                <FormBody>
                    <FormGroup label="Name" labelInfo="*">
                        <InputGroup large name="name" placeholder="Carrot" onChange={props.onChange} value={props.input['name'] || ''}/>
                        <NutritionixSearch onSelect={props.onNutritionixSelect}/>
                    </FormGroup>

                    <FormGroup label="Category">
                        <HTMLSelect large name="category" onChange={props.onChange} value={props.input['category']}>
                            <option key={0} value="" disabled selected>Select a category</option>
                            {aisles.map(aisle => <option key={aisle} value={aisle}>{_.capitalize(aisle)}</option>)}
                        </HTMLSelect>
                    </FormGroup>

                    <FormGroup label="Quantity" labelInfo="*">
                        <ControlGroup>
                            <NumericInputWrapper large buttonPosition="left" name="qty" min={1} onChange={props.onChange} value={ props.input['qty']}/>
                            <HTMLSelect large name="unit" onChange={props.onChange} value={props.input['unit']}>
                                <option key={0} value="" disabled selected>Select a unit</option>
                                {units.map(unit => <option key={unit} value={unit}>{unit}</option>)}
                            </HTMLSelect>
                        </ControlGroup>
                    </FormGroup>
                    <Button large type="submit" intent="primary">Save</Button>
                </FormBody>
                
                <FormSidebar>
                    <FormGroup label="Calories (Kcal)" labelInfo="*">
                        <InputGroup name="nf_calories" onChange={props.onChange} value={props.input['nf_calories']}/>
                    </FormGroup>
                    <FormGroup label="Fat (g)">
                        <InputGroup name="nf_fat" onChange={props.onChange} value={props.input['nf_fat']}/>
                    </FormGroup>
                    <FormGroup label="Saturates (g)">
                        <InputGroup name="nf_saturates" onChange={props.onChange} value={props.input['nf_saturates']}/>
                    </FormGroup>
                    <FormGroup label="Protein (g)">
                        <InputGroup name="nf_protein" onChange={props.onChange} value={props.input['nf_protein']}/>
                    </FormGroup>
                    <FormGroup label="Carbohydrates (g)">
                        <InputGroup name="nf_carbohydrates" onChange={props.onChange} value={props.input['nf_carbohydrates']}/>
                    </FormGroup>
                    <FormGroup label="Sugars (g)">
                        <InputGroup name="nf_sugars" onChange={props.onChange} value={props.input['nf_sugars']}/>
                    </FormGroup>
                    <FormGroup label="Fibre (g)">
                        <InputGroup name="nf_fibre" onChange={props.onChange} value={props.input['nf_fibre']}/>
                    </FormGroup>
                    <FormGroup label="Salt (g)">
                        <InputGroup name="nf_salt" onChange={props.onChange} value={props.input['nf_salt']}/>
                    </FormGroup>
                </FormSidebar>
            </FormWrapper>
        </React.Fragment>
    );
};

export default IngredientForm;
