import React, { useEffect, useState } from 'react';
import { MenuItem, Intent } from '@blueprintjs/core';
import { MultiSelect } from '@blueprintjs/select';
import { ApolloConsumer } from 'react-apollo';
import gql from 'graphql-tag';

const FETCH_TAGS = gql`
    query {
        listTags
    }
`;

const TagsSelector = props => {
    const [suggestions, setSuggestions] = useState([]);
    const [selection, setSelection] = useState([]);

    const _renderSuggestions = (item, { modifiers, handleClick, query }) => {
        if (!modifiers.matchesPredicate) {
            return null;
        }
        //console.log(item.match(new RegExp(query, 'gi')));
        const isActive = query.length > 0 && item.indexOf(query) > -1 ? true : false;
        return (
            <MenuItem
                active={isActive}
                disabled={modifiers.disabled}
                label={item}
                key={item}
                onClick={handleClick}
            />
        );
    }

    const _updateSuggestions = suggestions => {
        setSuggestions(suggestions);
    }

    const _tagRenderer = item => {
        return item;
    }

    const _onItemSelect = item => {
        const newSelection = [...selection, item];
        props.onChange(newSelection)
    }

    const _onItemDeselect = (item, index) => {
        const newSelection = selection.filter((item, i) => i !== index);
        props.onChange(newSelection)
    }

    useEffect(() => {
        setSelection(props.selection || [])
    }, [props.selection])

    return(
        <ApolloConsumer>
            {client => (
                <MultiSelect
                    activeItem={null}
                    tagInputProps={{ fill: true, onRemove: _onItemDeselect, onAdd: _onItemSelect }}
                    itemRenderer={_renderSuggestions}
                    items={suggestions}
                    popoverProps={{ 'minimal': true }}
                    onQueryChange={async (query) => {
                        const { data } = await client.query({
                            'query': FETCH_TAGS
                        });
                        _updateSuggestions(data.listTags);
                    }}
                    tagRenderer={_tagRenderer}
                    onItemSelect={_onItemSelect}
                    selectedItems={selection}
                    resetOnSelect={true}
                    noResults={<MenuItem disabled={true} />}
                />
            )}
        </ApolloConsumer>
    );
}

export default TagsSelector;