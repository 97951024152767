import React from 'react';
import { Link } from 'react-router-dom';
import { Cell, Column, Table } from "@blueprintjs/table";
import moment from "moment";
import _ from "lodash";

const ContentTypeTable = props => {

    const cellRenderer = (rowIndex, columnIndex) => {
        const label = props.headers[columnIndex].key;
        const row = props.rows[rowIndex];
        var value = row[label];

        if(label === 'created_at') {
            value = moment(parseInt(row[label])).format("YYYY-MM-DD")
        }

        if(label.indexOf("qty") > -1 || label.indexOf("calories") > -1) {
            value = parseInt(row[label])/process.env.REACT_APP_NUTRITION_BASE;
        }

        if(label === 'tags') {
            value = row[label].join(', ')
        }

        if(label === 'is_pro') {
            value = row[label] ? 'pro' : 'free';
        }

        if(label === 'category') {
            value = _.capitalize(value);
        }

        return (
            <Cell interactive={true}>
                <React.Fragment> {/* workaround for https://github.com/palantir/blueprint/issues/2446 */}
                    { label === 'name' || label === 'title' ? 
                        <Link to={`/${props.typename}s/editor/${row['id']}`}>{value}</Link>
                        :
                        value
                    }
                </React.Fragment>
            </Cell>
        );
    };

    const dummyCellRenderer = () => {
        return <Cell loading/>
    };

    const { rows, columnWidths } = props;
    const columns = props.headers.map(header => <Column loading={props.loading} key={header.key} name={header.label} cellRenderer={props.loading ? dummyCellRenderer : cellRenderer}/>);
   
    return(
        <Table numRows={typeof rows === 'number' ? rows : rows.length} columnWidths={columnWidths}>
            {columns}
        </Table>
    );
};

export default ContentTypeTable;