import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import WrapperDiv from '../WrapperDiv';
import PageHeader from './PageHeader';
import { ContentTypeTable, TableHeader, TableFooter } from '../ContentTypeTable';
import Pagination from '../Pagination';

import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';

const FETCH_RECIPES= gql`
    query($page: Int, $perPage: Int, $searchKey: String, $searchFields: String, $searchTags: String, $sortBy: String, $sortingOrder: String) {
        listRecipes(page: $page, per_page: $perPage, search_key: $searchKey, search_fields: $searchFields, search_tags: $searchTags, sortBy: $sortBy, sortingOrder: $sortingOrder) {
            results {
                id
                title
                difficulty
                preptime
                rp_calories
                tags
                is_pro
                publishing_status
                created_at
            }
            pagination {
                current
                total
            }
        }
    }
`;

const Recipes = props => {
    const [searchType, setSearchType] = useState('defeault');
    
    const location = useLocation();
    let params = new URLSearchParams(location.search);

    const { loading, error, data, refetch } = useQuery(FETCH_RECIPES, {
        variables: {
            page: parseInt(params.get('page')) || 1,
            perPage: parseInt(params.get('pageSize')) || 25
        }
    });

    const headers = [
        { key: 'id', label: 'Id' },
        { key: 'title', label: 'Title' },
        { key: 'difficulty', label: 'Difficulty' },
        { key: 'preptime', label: 'Prep time' },
        { key: 'rp_calories', label: 'Calories' },
        { key: 'tags', label: 'Tags' },
        { key: 'is_pro', label: 'Free or Pro' },
        { key: 'publishing_status', label: 'Status' },
        { key: 'created_at', label: 'Created' }
    ];

    var sortBy = 'created_at';
    var sortingOrder = 'desc';

    const _onSearch = (keyword) => {
        return refetch(searchType === 'tags' ? {
            searchTags: keyword
        } : {
            searchKey: keyword,
            searchFields: 'title,method'
        });
    };

    const _onSort = (column) => {
        sortBy = column;
        return refetch({
            sortBy: column
        });
    }

    const _onSortOrder = () => {
        sortingOrder = sortingOrder === 'desc' ? 'asc' : 'desc';
        return refetch({ sortingOrder });
    }

    const _onChangePage = (page, pageSize) => {
        // add or update page number in url query
        let currentURLParams = new URLSearchParams(window.location.search);
        currentURLParams.set('page', page);
        currentURLParams.set('pageSize', pageSize);
        props.history.push({ pathname: window.location.path, search: currentURLParams.toString()})
    };

    if( loading ) return <>Loading...</>;
    if( error ) return <>Error {error}</>;

    const recipes = data.listRecipes.results;
    const pagination = data.listRecipes.pagination;

    return(
        <WrapperDiv>
            <PageHeader title="Recipes" button={{href:"/recipes/editor", icon:"add", text:"Add recipe"}}/>
            <React.Fragment>
                <TableHeader 
                    onSearch={ _onSearch }
                    searchType={searchType}
                    onChangeSearchType={(e) => { setSearchType(e.target.value) }}
                    hasSorting={true}
                    sortedBy={sortBy}
                    onSort={(e) => { _onSort(e.target.value) }}
                    sortableColumns={[ 'difficulty', 'is_pro', 'publishing_status', 'created_at' ]}
                    onSortOrder={ _onSortOrder }
                    sortingOrder={sortingOrder} 
                />
                <ContentTypeTable 
                    headers={ headers } 
                    rows={ recipes } 
                    columnWidths={[80, 360, 100, 100, 100, 200, 100, 140, null]} 
                    typename='recipe'
                />
                <TableFooter>
                    <Pagination onChange={ _onChangePage } currentPage={pagination.current} pageSize={25} totalItems={pagination.total}/>
                </TableFooter>
            </React.Fragment>
        </WrapperDiv>
    );
};

export default Recipes;